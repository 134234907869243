:root {
  --color-one: #2eaf7d;
  --color-two: #3fd0c9;
  --color-three: #449342;
  --color-four: #02353c;
}

.PaginationBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-top: 20px;
}

.PaginationBarItem {
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--color-four);
  transition: 300ms;
  color: #fff;
  border-radius: 2px;
  min-height: 30px;
  min-width: 30px;
  margin-right: 1px;
  margin-left: 1px;
  padding: 0 4px;
}

.PaginationBarItem:hover {
  background-color: var(--color-three);
}

.PaginationBarItem.CurrentPage {
  background-color: var(--color-one);
}
