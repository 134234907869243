@import url("./fonts/IranSans.css");

html {
  direction: rtl;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: IRANSans, "Times";
  text-align: right;
  display: flex;
  width: 100%;
  height: 100%;
}

.table td {
  vertical-align: middle;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

#root,
.App,
.background {
  width: 100%;
  height: 100%;
}

.background {
  background: url("./images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.panel-container {
  position: fixed;
  width: 95%;
  height: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.main-section {
  background-color: #f5f5fb;
  border-radius: 20px;
  width: 100%;
  min-height: 600px;
  overflow: hidden;
  box-shadow: 0 0 20px rgb(72 72 72 / 27%);
}

@media only screen and (max-width: 1700px) {
  .panel-container {
    width: 100%;
    height: 100%;
  }
  .main-section {
    border-radius: 0;
  }
}

.sidebar {
  width: 230px;
  background-color: #ffffff;
  border-left: 1px solid #c9cfde;
  padding-top: 45px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
}

.sidebar > .title {
  width: 100%;
  color: #e8546d;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  font-size: 23px;
  padding: 0 50px;
}

.sidebar > .title img {
  width: 100%;
}

.sidebar > .menu > ul {
  background-color: #fefefe;
}

.sidebar > .menu > ul {
  padding: 0;
  margin: 0;
  margin-top: 40px;
  width: 100%;
}

.sidebar > .menu > ul > li {
  display: flex;
  flex-direction: row;
  height: 60px;
}

.sidebar > .menu > ul > li > a {
  color: #9696bb;
  font-size: 17px;
  display: flex;
  padding: 0 40px;
  width: 100%;
  text-decoration: none;
  font-weight: 400;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.sidebar > .menu > ul > li > a:hover {
  color: #131336;
}

.sidebar > .menu > ul > li > a:hover > i {
  color: #5e81f4;
}

.sidebar > .menu > ul > li > a.active {
  color: #131336;
  position: relative;
}

.sidebar > .menu > ul > li > a.active:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 30px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #5e81f4;
  left: 0;
}

.sidebar > .menu > ul > li > a.active > i {
  color: #5e81f4;
}

.sidebar > .menu > ul > li > a > .menu-icon {
  margin-left: 20px;
  width: 22px;
  height: 22px;
  font-size: 22px;
  text-align: center;
}

.sidebar > .power-up-container {
  width: 100%;
  height: 200px;
  padding: 0 20px;
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar > .power-up-container:before {
  content: "";
  position: absolute;
  background-image: url("./icons/data.svg");
  background-size: cover;
  width: 80px;
  height: 80px;
  top: -40px;
}

.sidebar > .power-up-container > .power-up-message {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5fb;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  flex-direction: column;
}

.sidebar > .power-up-container > .power-up-message > span {
  color: #9696bb;
  text-align: center;
  font-size: 13px;
  cursor: default;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 25px;
}

.sidebar > .power-up-container > .power-up-message > span > strong {
  color: #131336;
  font-weight: 800;
}

.btnStyle {
  border-radius: 10px;
  outline: none;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 15px;
  text-align: center;
  padding: 7px 10px;
  flex-direction: row;
}

.btnStyle:focus {
  outline: none;
}

.btnStyle_1 {
  background-color: #5e81f4;
  color: #ffffff;
}

.content {
  width: 100%;
  min-height: 100%;
  align-items: stretch;
  padding: 45px 40px 30px 40px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #c4c4c4;
}

.content .section-title {
  display: inline-block;
  padding-bottom: 10px;
  color: #131336;
}

.section-header > .button-container {
  display: inline-block;
}

.section-header > .button-container > button {
  width: 35px;
  height: 35px;
  padding: 0;
}

/* Modal - START */

.vida-modal-container {
  background-color: rgb(0 0 0 / 13%);
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 200ms ease-out;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.vida-modal-container > div {
  width: 600px;
  height: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}

/* Modal - End */

/* PaginationBar - START */
.page-item:first-child .page-link {
  border-radius: unset;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-radius: unset;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
/* PaginationBar - End */

/* Dialog - START */

.vida-dialog {
  background-color: rgb(0 0 0 / 13%);
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 200ms ease-out;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.vida-dialog > div {
  width: 30%;
  height: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}

/* Dialog - End */

.tick-container {
  fill: #44c4a1;
}

.vida-tick-svg,
.cross-rtl,
.cross-ltr {
  stroke-linecap: round;
  stroke-width: 8;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.cross-rtl,
.cross-ltr {
  stroke: #ed1c24;
  stroke-width: 8;
  stroke-dasharray: 70;
  stroke-dashoffset: 70;
}

.cross-rtl {
  animation: red-cross 500ms ease-in 300ms;
  animation-fill-mode: both;
}

.cross-ltr {
  animation: red-cross 500ms ease-in 800ms;
  animation-fill-mode: both;
}

.vida-tick-svg {
  stroke: #06c58a;
  stroke-dasharray: 100;
  stroke-dashoffset: -100;
  animation: appear-tick 1500ms ease-in;
  animation-fill-mode: forwards;
}

@keyframes appear-tick {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes red-cross {
  to {
    stroke-dashoffset: 0;
  }
}

.tick-icon {
  background-image: url(./icons/tick.svg);
  width: 100px;
  height: 100px;
}

/* Toggle Button ---- Start */

.vida-toggle-button {
  width: 60px;
  height: 30px;
  background-color: #e2e2e2;
  border-radius: 30px;
  display: block;
  border: 1px solid #dadada;
  position: relative;
  box-sizing: content-box;
  cursor: pointer;
  transition: 300ms ease;
}

.vida-toggle-button:after {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 100%;
  position: absolute;
  background-color: #fff;
  transition: 300ms ease;
  left: 2px;
  top: 2px;
}

.vida-toggle-button.active {
  background-color: #2196f3;
  border: 1px solid #2196f3;
}

.vida-toggle-button.active:after {
  transform: translateX(30px);
}
/* Toggle Button ---- End */

.device-info-container > span.label {
  line-height: 32px;
}

.device-info-container .button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
}

.user-info-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 50px;
  column-gap: 50px;
  align-items: start;
}

.user-info-container > section {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(187, 187, 187, 0.1);
  padding: 20px 20px 40px;
}

.user-info-container > section > .user-section-header,
.user-devices-section-header {
  margin-bottom: 30px;
}

.user-info-container > section .title {
  color: #204abd;
}

.user-info-container > section > .user-info-box {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  column-gap: 10px;
  row-gap: 20px;
  align-items: center;
  color: #5c5c5c;
}

.user-info-container > section > .user-info-box > span:nth-child(3n-2) {
  font-weight: 900;
  color: #007786;
}

.icon-btn {
  cursor: pointer;
  padding: 10px;
}

.icon-btn:hover {
  color: #204abd;
}

.devices-box {
  color: #5c5c5c;
}
.checkbox-container > div {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 20px;
}

.checkbox-container > div:first-child > div {
  display: grid;
  grid-template-columns: 150px 50px;
  row-gap: 10px;
}

.login-page-container {
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  width: 60%;
  /* height: 60%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5fb;
  box-sizing: border-box;
}

.login-page-container > .main-section {
  background-color: #fff;
}

.login-page-container > .main-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}

.login-title {
  color: #204abd;
}

.login-form-container {
  border-left: 1px dashed #cacaca;
}

.login-page-container .tmb-logo {
  width: 100px;
  margin-bottom: 40px;
}

.report-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #bbbbbb;
  border-radius: 5px;
}

.report-container > .report-header {
  display: flex;
  align-items: flex-start;
  align-items: center;
  flex-direction: row;
  height: 40px;
  width: 100%;
  background-color: #e2e2e2;
  border-bottom: 2px solid #bbbbbb;
}

.report-container > .report-content {
  padding: 5px;
}

.report-container > .report-content table {
  font-size: 0.7vw;
  text-align: center;
}

.report-content .table td,
.report-content .table th {
  padding: 0.5rem;
}
.hardware-button-container {
  display: flex;
  flex-direction: column;
}

.about-us-container {
  line-height: 28px;
}

.about-us-container .about-us-text {
  text-align: justify;
  margin-bottom: 30px;
}

.nYegl {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 13px;
  align-items: center;
  height: 100%;
}

.nYegl > label {
  margin: 0 0 0 10px;
}

.info-container {
  display: grid;
  grid-template-columns: 1fr 6fr;
  row-gap: 20px;
  width: 70%;
}

.info-container > span:nth-child(2n) {
  font-weight: bold;
}

.newDeviceFormInputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dashboard-table-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.dashboard-table-container > div {
  margin-bottom: 40px;
}

.dashboard-table-container .table thead th {
  background-color: #008a6c;
  border-color: #007e62;
}

.dashboard-table-container h6 {
  white-space: pre-line;
  line-height: 35px;
}

.numberOfStatisticsContainer {
  border: 1px solid #008a6c;
  padding: 10px 20px;
  margin: 20px 30px 20px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 10px;
}

.new-user-input-conatiner {
  width: 50%;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
