.VidaDropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: flex-end;
  height: 70px;
  margin-bottom: 1rem;
}

.VidaDropdownContainer > label {
  float: right;
  min-width: 150px;
  margin-left: 10px;
  position: relative;
}
.LabelContainer {
  font-size: 13px;
  margin-bottom: 5px;
}

.LabelContainer > label {
  margin-left: 10px;
}

.LabelContainer > span {
  display: inline-flex;
}

.VidaDropdownContainer > .DropdownSelect {
  line-height: 38px;
  padding-right: 10px;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}

.VidaDropdownContainer > .DropdownSelect:focus {
  border: 1px solid var(--input-border-hover-color);
  box-shadow: var(--input-focus-shadow-color) 0px 0px 0px 0.2rem;
}

.VidaDropdownContainer > .DropdownSelect:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 18px;
  height: 18px;
  left: 10px;
  color: black;
  position: absolute;
}
